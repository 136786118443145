












import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class HelpBlock extends Vue {
  private showHelp = true;

  @Prop({required: true, type: String })
  private text!: string;
}
