






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class SectionTitle extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private title!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  private hr!: boolean;
}
