





















































import { Component, Vue } from 'vue-property-decorator';
import HelpBlock from '@/components/HelpBlock.vue';
import Rating from '@/components/Ui/Rating.vue';
import SectionTitle from '@/components/Section/Section.title.vue';
import {i18n} from '@/main';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.rate.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.rate.description')
      }]
    };
  },
  components: {
    HelpBlock,
    Rating,
    SectionTitle
  }
})
export default class RatingTenantPage extends Vue {
  private ratingInfo = {
    relationship: 0,
    checkout: 0,
    recommend: 'true',
    additionalMessage: '',
    ratingId: this.$route.params.id
  };

  private confirmRate() {
    this.$store.dispatch('setRate', this.ratingInfo)
      .then(() => this.$router.push({ name: 'rating-thank' }));
  }

  private created() {
    this.$store.dispatch('rateCheck', this.$route.params.id);
  }

  private get checkRating(): boolean {
    return this.ratingInfo.relationship === 0 || this.ratingInfo.checkout === 0;
  }
}
